const config = {
    brand: {
        name: ['De','Online','Boekwinkel'],
        imgSrc: './frontpage.jpg'
    }, 
    account: {
        imgSrc: './accountpage.jpg'
    },
    cards: [
        {
            imgSrc: './news.jpg',
            title: 'Het luchtige lezen',
            subtitle: 'Zon, zee, strand en een heerlijke stapel zomerse literatuur!'
        }
    ]
}

export { config }